<template>
    <div class="loading-container">
      <div v-if="loading" class="loader-wrapper">
        <svg class="loader" viewBox="0 0 50 50">
          <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
        </svg>
      </div>
      <div v-else>
        <slot></slot>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      loading: {
        type: Boolean,
        required: true
      }
    }
  };
  </script>
  
  <style scoped>
  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100px;
    position: relative;
  }
  
  .loader-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .loader {
    animation: rotate 2s linear infinite;
    width: 50px;
    height: 50px;
  }
  
  .path {
    stroke: #3498db;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
  
  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -125;
    }
  }
  </style>
  