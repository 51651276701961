<template>
  <!-- Loader Component -->

  <div class="box" v-if="profile">
    <div class="header">
      <img
        class="profile-pic"
        :src="profile.profile_photo ? profile.profile_photo : defaultImage"
      />
    </div>
    <div class="profile-details text-center">
      <h2 class="fs-5">{{ profile.first_name + " " + profile.last_name }}</h2>
      <span class="about-of-profile-details">
        {{ profile.about_me }}
        <br />
        {{ city }}
      </span>
      <br />

      <RouterLink v-if="['1', '2', '3'].includes(profile.user_type)" to="/Login">
        <button class="btn btn-danger center mb-3" @click="$router.push({ name: 'Login' })">
          View Profile
        </button>
      </RouterLink>
    </div>
  </div>

  <div v-else>
    <p>No profile available.</p>
  </div>
  <LoadingComponent :loading="isLoading" />

</template>

<script>
import axios from "axios";
import LoadingComponent from "./LoadingComponent.vue";

export default {
  name: "DummyLeftSide",
  components: {
    LoadingComponent,
  },
  data() {
    return {
      isLoading: false, // Loader state
      token: "",
      profile: null, // Changed from array to object
      defaultImage: require("./assets/profile-no-img.png"),
      apiUrl: "https://api.garbajockey.com/api/",
    };
  },
  methods: {
    async fetchProfile() {
      this.isLoading = true; // Show loader

      try {
        const userType = this.$store.state.userType;
        let endpoint = "";

        switch (userType) {
          case "1":
            endpoint = "entrant/my-profile";
            break;
          case "2":
            endpoint = "gj/my-profile";
            break;
          case "3":
            endpoint = "gc/my-profile";
            break;
          default:
            endpoint = "default-profile";
        }

        const response = await axios.get(`${this.apiUrl}${endpoint}`, {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        });

        this.profile = response.data.data;
      } catch (error) {
        console.error("Error fetching profile:", error);
      } finally {
        this.isLoading = false; // Hide loader
      }
    },
    loadToken() {
      this.token = localStorage.getItem("authToken") || "";
    },
  },
  created() {
    this.loadToken();
  },
  mounted() {
    this.fetchProfile();
  },
};
</script>

<style scoped>
.box {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 0 7px #d4d4d4;
  background-color: #ffff;
}

.header {
  background-color: #c10111;
  height: 10%;
  text-align: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.profile-pic {
  width: 6.8rem;
  height: 6rem;
  position: relative;
  top: 43px;
  box-shadow: 0 0px 9px #4d4d4d;
  border-radius: 50%;
}

.profile-details {
  margin: 53px 0;
  padding: 10px;
}

.box-2 {
  border-radius: 10px;
  box-shadow: 0 0 7px #d4d4d4;
  background: white;
  padding: 10px 10px 8px;
}

.images {
  height: 78px;
  margin: 17px 6px 4px;
}

@media (max-width: 992px) {
  .col-lg-3 {
    display: none;
  }

  .col-lg-6 {
    width: 100%;
  }
}

@media screen and (min-width: 1440px) {
  .profile-pic {
    height: 7rem;
    width: 7.8rem;
  }
}
</style>
