<template>
  <main>
    <!-- Navbar for larger screens -->
    <div class="container-xl d-none d-md-flex justify-content-center w-100">
      <nav
        class="d-flex w-100 justify-content-around align-items-center fixed-top"
        style="background: #c90000; padding: 1.3rem 0.5rem"
      >
        <div class="row align-items-center">
          <div class="col-sm-8 col-md-11 col-xl-4">
            <RouterLink
              to="/"
              class="d-flex justify-conent-center align-items-center"
            >
              <img
                src="./assets/logo.png"
                alt="logo of Garbajockey"
                class="w-100"
                style="object-fit: contain"
              />
            </RouterLink>
          </div>

          <div class="col-sm-8 col-8 d-none d-xl-flex">
            <h3 class="fw-bold fs-5 mb-0" style="color: white">
              Empowering Passionate Garba Instructors
            </h3>
          </div>
        </div>

        <ul class="d-flex gap-3 mb-0">
          <li
            class="list-unstyled p-2 bg-white rounded custom-hover d-flex align-items-center justify-content-center"
            style="width: 6rem; cursor: pointer"
            @click="logout"
          >
            <button
              class="text-decoration-none text-black fs-5"
              style="border: none; background: none"
              @click="logout"
            >
              Login
            </button>
          </li>

          <RouterLink
            to="/sign-up"
            class="text-decoration-none text-black fs-5"
          >
            <li
              class="list-unstyled p-2 bg-white rounded custom-hover d-flex align-items-center justify-content-center"
              style="width: 6rem; cursor: pointer"
            >
              Sign-Up
            </li>
          </RouterLink>
          <RouterLink
            to="/IBTalks"
            class="text-decoration-none text-black fs-5"
          >
            <li
              class="list-unstyled p-2 bg-white rounded custom-hover d-flex align-items-center justify-content-center"
              style="width: 6rem; cursor: pointer"
            >
              IB-Talks
            </li>
          </RouterLink>
          <RouterLink
            to="/the-phoenix-tales"
            style="white-space:nowrap;"
            class="text-decoration-none text-black "
          >
            <li
              class="list-unstyled p-2 bg-white rounded custom-hover d-flex align-items-center justify-content-center"
              style="width: 12rem; cursor: pointer"
            >
            The Phoenix Tale
            </li>
          </RouterLink>
          <RouterLink
            to="/all-blogs"
            class="text-decoration-none text-black fs-5"
          >
            <li
              class="list-unstyled p-2 bg-white rounded custom-hover d-flex align-items-center justify-content-center"
              style="width: 6rem; cursor: pointer"
            >
              Blogs
            </li>
          </RouterLink>
          <RouterLink
            to="/Tutorial-Page"
            class="text-decoration-none text-black fs-5"
          >
            <li
              class="list-unstyled p-2 bg-white rounded custom-hover d-flex align-items-center justify-content-center"
              style="width: 6rem; cursor: pointer"
            >
              Tutorials
            </li>
          </RouterLink>
        </ul>
      </nav>
    </div>

    <!-- Navbar for smaller screens -->
    <nav
      class="navbar navbar-expand-md navbar-light d-md-none p-3 justify-content-between"
      style="background-color: #c90000"
    >
      <div class="container">
        <RouterLink to="/" class="navbar-brand">
          <img
            src="./assets/logo.png"
            alt="logo of Garbajockey"
            class="w-100 custom-logo"
            style="object-fit: contain"
          />
        </RouterLink>

        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
          @click="toggleNavbar"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>

      <!-- Use the SideNavigation component -->
      <DummySideNavBar
        v-if="isSideNavigationOpen"
        @closeSidebar="closeSidebar"
      />
    </nav>
  </main>
</template>
  
  <script>
import DummySideNavBar from "./DummySidebar.vue";
export default {
  name: "DummyNavbar",
  data() {
    return {
      isSideNavigationOpen: false,
    };
  },

  created() {
    this.lodData();
  },

  methods: {
    lodData() {
      this.token = localStorage.getItem("authToken");
      //   console.log("this is home page show token", this.token);
    },

    logout() {
      localStorage.removeItem("authToken");
      this.$router.push("/login");
    },

    toggleNavbar() {
      this.isSideNavigationOpen = true;
    },

    closeSidebar() {
      this.isSideNavigationOpen = false;
    },
  },
  components: { DummySideNavBar },
};
</script>
  
  <style scoped>
* {
  font-size: 1.4rem;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-weight: 500;
}

.custom-hover {
  transition: transform 0.3s ease;
}

.custom-hover:hover {
  transform: scale(1.06);
}

@media (max-width: 457px) {
  .custom-logo {
    width: 75% !important;
  }
}

@media screen and (min-width: 768) and (max-width: 930) {
  .list-unstyled {
    font-size: medium !important;
  }
}

/* @media (max-width: 457px) {
    .custom-right-padding {
      padding-left: 1rem !important;
    }
  } */
</style>