<template>
  <!-- Loader Component -->

  <div class="suggestion-box w-100 p-3">
    <div class="title d-flex justify-content-between align-items-center">
      <span class="fw-semibold">Suggestion for you</span>
      <p class="text-dark fw-semibold mb-0" style="cursor: pointer" @click="$router.push({ name: 'Login' })">
        See All
      </p>
    </div>
    <hr />

    <div class="row">
      <div
        v-for="suggestion in suggestions"
        :key="suggestion.id"
        class="col-6 d-flex flex-column align-items-center text-center mb-3"
      >
        <img
          :src="suggestion.profile_photo ? suggestion.profile_photo : defaultImage"
          class="suggestion_img"
          alt="profile-img"
        />
        <p class="mt-2 mb-1 fw-semibold">
          {{ suggestion.first_name }} {{ suggestion.last_name }}
        </p>

        <button
          v-if="!suggestion.is_following"
          class="btn btn-danger suggestion-btn"
          @click="$router.push({ name: 'Login' })"
        >
          Follow
        </button>

        <button
          v-if="suggestion.is_following"
          class="btn btn-outline-danger suggestion-btn"
          @click="$router.push({ name: 'Login' })"
        >
          Unfollow
        </button>
      </div>
    </div>

    <router-link to="/Login">
      <button type="button" class="btn btn-danger w-100 mt-2">Contact Us</button>
    </router-link>
  </div>
  <LoadingComponent :loading="isLoading" />


  <RightAdBanner />
</template>

<script>
import axios from "axios";
import RightAdBanner from "./RightAdBanner.vue";
import LoadingComponent from "./LoadingComponent.vue";

export default {
  name: "DummyRightSide",
  components: {
    RightAdBanner,
    LoadingComponent, // Importing the Loader Component
  },
  data() {
    return {
      isLoading: false, // Loader state
      token: "",
      profile: [],
      suggestions: [],
      defaultImage: require("@/assets/profile-no-img.png"),
      apiUrl: "https://api.garbajockey.com/api/",
    };
  },
  methods: {
    async fetchSuggestions() {
      this.isLoading = true; // Show loader
      try {
        const response = await axios.get(`${this.apiUrl}suggestions`, {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        });
        this.suggestions = response.data.data.users;
      } catch (error) {
        console.error("Error fetching suggestions:", error);
      } finally {
        this.isLoading = false; // Hide loader when done
      }
    },
    loadToken() {
      this.token = localStorage.getItem("authToken") || "";
    },
  },
  created() {
    this.loadToken();
  },
  mounted() {
    this.fetchSuggestions();
  },
};
</script>

<style scoped>
.suggestion-box {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 7px #d4d4d4;
  font-size: 0.9em;
}

.suggestion-box p {
  font-size: 14px;
  margin: 0;
}

.title {
  padding: 6px;
}

.suggestion_img {
  width: 6rem;
  height: 6rem;
  object-fit: cover;
  border-radius: 50%;
  padding: 0.3rem;
}

.suggestion-btn {
  margin-top: 8px;
  padding: 4px 12px;
  width: 100%;
  font-size: 0.9em;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .suggestion_img {
    height: 4.8rem;
    width: 4.8rem;
  }
}

@media screen and (min-width: 1440px) {
  .suggestion_img {
    height: 6.8rem;
    width: 6.8rem;
  }
}
</style>
